import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import Logo from '../assets/image/Logo.png';
import User from '../assets/image/User.svg';

import '../components/Header.css';

function Header() {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const location = useLocation();
    const { pathname } = location;

    const closeDropdown = (event) => {
        if (!event.target.closest('.dropdown')) {
            setIsDropdownOpen(false);
        }
    };

    React.useEffect(() => {
        if (isDropdownOpen) {
            document.addEventListener('click', closeDropdown);
        } else {
            document.removeEventListener('click', closeDropdown);
        }

        return () => {
            document.removeEventListener('click', closeDropdown);
        };
    }, [isDropdownOpen]);
    return (
        <>
            <div className="bg-bgsecondary py-2 px-1">
                <p className='text-white text-center text-lg flex justify-center items-center gap-4'>
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-party-popper"><path d="M5.8 11.3 2 22l10.7-3.79"/><path d="M4 3h.01"/><path d="M22 8h.01"/><path d="M15 2h.01"/><path d="M22 20h.01"/><path d="m22 2-2.24.75a2.9 2.9 0 0 0-1.96 3.12c.1.86-.57 1.63-1.45 1.63h-.38c-.86 0-1.6.6-1.76 1.44L14 10"/><path d="m22 13-.82-.33c-.86-.34-1.82.2-1.98 1.11c-.11.7-.72 1.22-1.43 1.22H17"/><path d="m11 2 .33.82c.34.86-.2 1.82-1.11 1.98C9.52 4.9 9 5.52 9 6.23V7"/><path d="M11 13c1.93 1.93 2.83 4.17 2 5-.83.83-3.07-.07-5-2-1.93-1.93-2.83-4.17-2-5 .83-.83 3.07.07 5 2Z"/></svg>
                    </span>
                    <span>
                        Make your profile now for free with our <strong className='font-bold'>Premium Package. </strong>
                        <Link to='https://profile.flicknlink.com/' target='_blank' className='underline text-lg'>Create Now</Link>
                    </span>
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-party-popper"><path d="M5.8 11.3 2 22l10.7-3.79"/><path d="M4 3h.01"/><path d="M22 8h.01"/><path d="M15 2h.01"/><path d="M22 20h.01"/><path d="m22 2-2.24.75a2.9 2.9 0 0 0-1.96 3.12c.1.86-.57 1.63-1.45 1.63h-.38c-.86 0-1.6.6-1.76 1.44L14 10"/><path d="m22 13-.82-.33c-.86-.34-1.82.2-1.98 1.11c-.11.7-.72 1.22-1.43 1.22H17"/><path d="m11 2 .33.82c.34.86-.2 1.82-1.11 1.98C9.52 4.9 9 5.52 9 6.23V7"/><path d="M11 13c1.93 1.93 2.83 4.17 2 5-.83.83-3.07-.07-5-2-1.93-1.93-2.83-4.17-2-5 .83-.83 3.07.07 5 2Z"/></svg>
                    </span>
                </p>
            </div>
            <header>
                <nav className='px-10'>
                    <div className="button-logo">
                        <Link to='/' className="logo">
                            <img src={Logo} alt='logo' />
                        </Link>
                        <button
                            id='nav-icon'
                            className={`lg:hidden ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${isNavOpen ? 'toggle-open' : 'toggle-hidden'}`}
                            onClick={() => setIsNavOpen(!isNavOpen)}
                        >
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                    <div className={`menu-links ${isNavOpen ? 'menu-open' : 'menu-hidden'}`}>
                        <ul className="header-menu">
                            <li>
                                <Link
                                    to="/"
                                    className={`${pathname === '/' ? 'active-menu' : ''}`}
                                    onClick={() => setIsNavOpen(!isNavOpen)}
                                >
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/about"
                                    className={`${pathname === '/about' ? 'active-menu' : ''}`}
                                    onClick={() => setIsNavOpen(!isNavOpen)}
                                >
                                    About us
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/feature"
                                    className={`${pathname.startsWith('/feature') ? 'active-menu' : ''}`}
                                    onClick={() => setIsNavOpen(!isNavOpen)}
                                >
                                    Feature
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/pricing"
                                    className={`${pathname === '/pricing' ? 'active-menu' : ''}`}
                                    onClick={() => setIsNavOpen(!isNavOpen)}
                                >
                                    Pricing
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/review"
                                    className={`${pathname === '/review' ? 'active-menu' : ''}`}
                                    onClick={() => setIsNavOpen(!isNavOpen)}
                                >
                                    Review
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/contact"
                                    className={`${pathname === '/contact' ? 'active-menu' : ''}`}
                                    onClick={() => setIsNavOpen(!isNavOpen)}
                                >
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="login">
                        <Link to='https://profile.flicknlink.com/' target='_blank'>
                            <img src={User} alt='logo' />
                        </Link>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default Header;