import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';

import Chevron from '../assets/image/chevron-right.svg';
import LogoWhite from '../assets/image/LogoWhite.png';
import axios from 'axios';
import Swal from "sweetalert2";

function Footer() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    const [formData, setFormData] = useState({
        email: ''
    });
    
    const [errors, setErrors] = useState({});
    
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
     };
    
    const validateForm = () => {
        const newErrors = {};
        
        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email address is invalid';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    
    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm()) {
            axios.post('https://profile.flicknlink.com/api/newsletter',formData)
            .then(response => {
                if(response.data === 'success') {
                    Swal.fire({
                      title: "Success!",
                      text: "Your mail added successfully",
                      icon: "success",
                      confirmButtonText: "OK",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        setFormData({
                            email : ''
                        });
                      }
                    });
                } else if(response.data === 'fail') {
                    Swal.fire({
                      title: "Fail!",
                      text: "There are some issue",
                      icon: "error",
                      confirmButtonText: "OK",
                    });
                }
            }).catch(err => {
            });
        }
    };
    return (
        <>
            <footer>
                <div className='container'>
                    <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-4 gap-10'>
                        <div>
                        <Link to='/' className="logo">
                            <img src={LogoWhite} alt='LogoWhite' />
                        </Link>
                            <ul>
                                <li>
                                    <Link to="mailto:flicknlink@gmail.com">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                                        </svg>
                                        flicknlink@gmail.com
                                    </Link>
                                </li>
                                <li>
                                    <Link to="tel:+917016555196">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                                        </svg>
                                        +91 7016555196
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://maps.app.goo.gl/uZRukLKVkmkaJ2UA7" target='_blank'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-map-pin">
                                            <path d="M20 10c0 4.993-5.539 10.193-7.399 11.799a1 1 0 0 1-1.202 0C9.539 20.193 4 14.993 4 10a8 8 0 0 1 16 0"></path>
                                            <circle cx="12" cy="10" r="3"></circle>
                                        </svg>
                                        E - 604 PNTC, Near times of India office, Vejalpur - 380015.
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h5>Quick Link</h5>
                            <ul>
                                <li><Link to='/'> <img src={Chevron} alt='chevron' /> Home</Link></li>
                                <li><Link to='/about'> <img src={Chevron} alt='chevron' /> About Us</Link></li>
                                <li><Link to='/feature'> <img src={Chevron} alt='chevron' /> Feature</Link></li>
                                <li><Link to='/pricing'> <img src={Chevron} alt='chevron' /> Pricing</Link></li>
                                <li><Link to='/review'> <img src={Chevron} alt='chevron' /> Review</Link></li>
                                <li><Link to='/contact'> <img src={Chevron} alt='chevron' /> Contact</Link></li>
                            </ul>
                        </div>
                        <div>
                            <h5>Policy's</h5>
                            <ul>
                                <li><Link to='/subscriptionpolicy'> <img src={Chevron} alt='chevron' /> Subscription Policy</Link></li>
                                <li><Link to='/shippingpolicy'> <img src={Chevron} alt='chevron' /> Shipping Policy</Link></li>
                                <li><Link to='/refundpolicy'> <img src={Chevron} alt='chevron' /> Refund Policy</Link></li>
                                <li><Link to='/terms'> <img src={Chevron} alt='chevron' /> Terms Policy</Link></li>
                                <li><Link to='/privacypolicy'> <img src={Chevron} alt='chevron' /> Privacy Policy</Link></li>
                            </ul>
                        </div>
                        <div>
                            <h5>Newsletter</h5>
                            <p>Subscribe to our newsletter to stay updated on the latest news, important announcements, and special offers from FlickNLinK.</p>
                            <div className='newsletter'>
                                <input placeholder='Enter Your Email' 
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                <div onClick={handleSubmit}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
                                        <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
                                    </svg>
                                </div>
                            </div>
                            {errors.email && <span className="error-message">{errors.email}</span>}
                        </div>
                    </div>
                    <hr />
                    <div className='copy-right'>
                        <div>
                            <Link>Copyright © 2024 FlickNLinK All Right Reserved.</Link>
                        </div>
                        <div className='copy-links'>
                            <Link to='https://wa.me/7016555196' target='_blank'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                                    <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"></path>
                                </svg>
                            </Link>
                            <span>|</span>
                            <Link to='https://www.instagram.com/flicknlink/' target='_blank'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-instagram"><rect width="20" height="20" x="2" y="2" rx="5" ry="5"/><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"/><line x1="17.5" x2="17.51" y1="6.5" y2="6.5"/></svg>
                            </Link>
                            <span>|</span>
                            <Link to='https://www.facebook.com/profile.php?id=61566660467153' target='_blank'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-facebook"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"/></svg>
                            </Link>
                            <span>|</span>
                            <Link to='https://www.youtube.com/@FlickNLink' target='_blank'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-youtube"><path d="M2.5 17a24.12 24.12 0 0 1 0-10 2 2 0 0 1 1.4-1.4 49.56 49.56 0 0 1 16.2 0A2 2 0 0 1 21.5 7a24.12 24.12 0 0 1 0 10 2 2 0 0 1-1.4 1.4 49.55 49.55 0 0 1-16.2 0A2 2 0 0 1 2.5 17"/><path d="m10 15 5-3-5-3z"/></svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;