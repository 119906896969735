import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async';

function SubscriptionPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <title>FlickNLinK Subscription Policy</title>
                <meta name="description" content="FlickNLinK Subscription Policy - Understand the terms of our subscription services for NFC business cards." />
                <meta name="keywords" content="FlickNLinK subscription policy, terms of service, NFC card subscriptions, digital card subscriptions" />
                <meta property="og:title" content="FlickNLinK Subscription Policy" />
                <meta property="og:description" content="Learn about FlickNLinK's subscription policies for our NFC business cards and services." />
                <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
                <meta property="og:url" content="https://flicknlinK.com/subscription-policy" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className='container policy'>
                <h1>Subscription Policy</h1>

                <h4>Cancellation Policy</h4>
                <p>At FlickNLinK, some of our services may be offered as subscriptions, pre-orders, or "try before you buy" options. This policy outlines how you can cancel or modify these purchases.</p>

                <h4>Subscriptions</h4>
                <p>When subscribing to FlickNLinK, you’ll receive recurring services based on the plan duration and frequency you choose. Your payment details are securely stored, and recurring charges will be applied unless prepaid. Some subscriptions may automatically renew; you can cancel or modify your subscription at any time via the link in your order confirmation email.</p>

                <h4>Pre-Orders</h4>
                <p>Pre-orders allow you to secure upcoming products before they’re available. You may be charged a deposit or full amount at checkout. You can cancel an unfulfilled pre-order anytime, but once fulfilled, you must follow our returns policy for any refunds.</p>

                <h4>Try Before You Buy</h4>
                <p>With our try-before-you-buy feature, your payment method is authorized but not charged immediately. You’ll have a set period to decide if you want to keep the product. If no return is made within this period, the full charge will be applied.</p>
            </div>
        </>
    )
}

export default SubscriptionPolicy
