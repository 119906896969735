import React, { useEffect, useState } from 'react';
import Testimonial from '../assets/image/testimonial-3.jpg';
import '../assets/css/Home.css';
import axios from 'axios';
import Button from '../components/Button';
import BannerSlider from '../components/Banner-slider';
import { Helmet } from 'react-helmet-async';

function Review() {
  const [ratting, setRatting] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios.get('https://profile.flicknlink.com/api/ratting')
      .then(response => {
        setRatting(response.data);
      })
      .catch(err => {
      });
  },
    []);
  return (
    <>
      <Helmet>
        <title>FlickNLinK Reviews | What Our Customers Say</title>
        <meta name="description" content="See what our customers have to say about FlickNLinK's NFC business cards. Read real reviews and testimonials from professionals." />
        <meta name="keywords" content="FlickNLinK reviews, customer feedback, NFC business card testimonials, digital card reviews" />
        <meta property="og:title" content="FlickNLinK Reviews - What Our Customers Say" />
        <meta property="og:description" content="Read reviews and testimonials from satisfied customers who have used FlickNLinK NFC business cards to enhance their networking." />
        <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
        <meta property="og:url" content="https://flicknlinK.com/reviews" />
        <meta property="og:type" content="website" />
      </Helmet>
      <section className='banner bg-banner bg-no-repeat bg-contain bg-bottom'>
        <div className='container'>
          <div className='grid lg:grid-cols-2 grid-cols-1 lg:gap-5 gap-10 pb-1'>
            <div>
              <h1>Review Connect</h1>
              <p>We greatly value the experiences and feedback of our clients. Their stories highlight the impact our innovative networking solutions have had on their professional lives. Explore their reviews and see how FlickNLinK is transforming business networking across various industries.</p>
              <div className='flex justify-start items-stretch gap-5 md:mt-10 mt-5'>
                <Button link='/contact' className='dark-btn' name="Contact Us" />
              </div>
            </div>
            <div className='banner-slider'>
              <BannerSlider />
            </div>
          </div>
        </div>
      </section>
      <section className='Testimonial'>
        <div className='container'>
          <h2>What Our Customers Say!</h2>
          <div className='grid lg:grid-cols-2 grid-cols-1 items-center lg:gap-5 gap-10 md:mt-20 mt-12'>
            {ratting.map((review, index) => (
              <ReviewCard key={index} data={review} />
            ))}
          </div>
        </div>
      </section>
    </>
  )
}


function ReviewCard(data) {
  var users = data.data.user_data;
  return (
    <>
      <div className='border-2 border-bgprimary rounded-xl p-5'>
        <div className='flex justify-start items-center gap-3 mb-4'>
          <img className='aspect-square border-2 w-14 border-bgprimary rounded-full overflow-hidden' src={Testimonial} alt='' />
          <div>
            {Object.entries(users).map(([key, value]) => (
              key === 'name' ? (
                <p className='font-bold mb-1'>{data.data.user_data.name}</p>
              ) : null
            ))}
          </div>
        </div>
        <div>
          <p>{data.data.comment}</p>
        </div>
      </div>
    </>
  )
}

export default Review;