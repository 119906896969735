import React, { useEffect } from 'react'

import '../assets/css/Home.css';
import Button from '../components/Button';
import ReviewSlide from '../components/ReviewSlide';
import BannerSlider from '../components/Banner-slider';
import { Helmet } from 'react-helmet-async';

function Feature() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>FlickNLinK Features | NFC Digital Business Cards</title>
        <meta name="description" content="Explore the innovative features of FlickNLinK's NFC digital business cards. Tap and share your profile instantly and securely." />
        <meta name="keywords" content="FlickNLinK features, NFC business cards features, digital card features, tap to share, contactless cards, smart cards" />
        <meta property="og:title" content="FlickNLinK Features - Discover What Sets Us Apart" />
        <meta property="og:description" content="Learn about the cutting-edge features of FlickNLinK's NFC business cards, designed to make networking smarter and easier." />
        <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
        <meta property="og:url" content="https://flicknlinK.com/features" />
        <meta property="og:type" content="website" />
      </Helmet>
      <section className='banner bg-banner bg-no-repeat bg-contain bg-bottom'>
        <div className='container'>
          <div className='grid lg:grid-cols-2 grid-cols-1 lg:gap-5 gap-10 pb-1'>
            <div>
              <h1>Feature Connect</h1>
              <p>At FlickNLinK, we offer a suite of innovative features designed to revolutionize your networking experience. From NFC business cards to secure digital profiles and eco-friendly QR codes, our tools make exchanging professional information fast, seamless, and sustainable.</p>
              <div className='flex justify-start items-stretch gap-5 md:mt-10 mt-5'>
                <Button link='/contact' className='dark-btn' name="Contact Us" />
              </div>
            </div>
            <div className='banner-slider'>
              <BannerSlider />
            </div>
          </div>
        </div>
      </section>
      <section className='services'>
        <div className='container'>
          <div>
            <h2>Awesome Card Features</h2>
          </div>
          <div className='services-card'>
            <div>
              <p className='card-heading'>Instant Sharing</p>
              <p>Share your contact details instantly with a simple tap or scan, making networking quick and effortless.</p>
            </div>
            <div>
              <p className='card-heading'>Eco-Friendly</p>
              <p>Reduce paper waste with our sustainable digital solutions, contributing to a greener and more environmentally friendly world</p>
            </div>
            <div>
              <p className='card-heading'>Customizable Profiles</p>
              <p>Personalize your digital profile to reflect your brand, ensuring a unique and professional presentation every time.</p>
            </div>
            <div>
              <p className='card-heading'>Secure Information</p>
              <p>Your data is protected with top-notch security measures, ensuring your information remains safe and confidential.</p>
            </div>
            <div>
              <p className='card-heading'>Easy Integration</p>
              <p>Seamlessly integrate with your existing digital tools, enhancing your workflow without any disruptions.</p>
            </div>
            <div>
              <p className='card-heading'>Analytics</p>
              <p>Track interactions and optimize your networking strategy with detailed analytics and insights.</p>
            </div>
            <div>
              <p className='card-heading'>Multi-Platform Access</p>
              <p>Access and share your digital profile from any device, whether it's your smartphone, tablet, or computer, ensuring you're always ready to connect, no matter where you are.</p>
            </div>
            <div>
              <p className='card-heading'>Smart Updates</p>
              <p>Easily update your contact information in real time, ensuring that your network always has your most current details without the need for reprinting or redistributing cards.</p>
            </div>
          </div>
        </div>
      </section>
      <ReviewSlide />
    </>
  )
}

export default Feature;