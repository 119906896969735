import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

function RefundPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <title>FlickNLinK Refund Policy</title>
                <meta name="description" content="FlickNLinK Refund Policy - Understand our refund and return policy for NFC business cards." />
                <meta name="keywords" content="FlickNLinK refund policy, return policy, NFC card refunds, digital card returns" />
                <meta property="og:title" content="FlickNLinK Refund Policy" />
                <meta property="og:description" content="Read about FlickNLinK's refund and return policy for our NFC business cards." />
                <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
                <meta property="og:url" content="https://flicknlinK.com/refund-policy" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className='container policy'>
                <h1>Refund Policy</h1>

                <p>We strive to ensure your satisfaction with every purchase. However, if you're not completely happy with your order, we offer replacements or returns under the conditions outlined in this policy. Please note that refunds are not provided for completed services, and any shipping fees are non-refundable.</p>

                <h4>Conditions for Return</h4>
                <p>We accept returns if:</p>
                <ul>
                    <li>The products received differ significantly from what was ordered.</li>
                    <li>The product is defective or damaged, with claims made within 48 hours of receipt.</li>
                    <li>If a return is accepted, we will provide return shipping labels and instructions. Items returned without prior approval will not be accepted.</li>
                </ul>

                <h4>Damages and Issues</h4>
                <p>Please inspect your order immediately upon receipt and contact us if any item is damaged or incorrect, so we can address the issue promptly.</p>

                <h4>Non-Returnable Items</h4>
                <p>Certain items, such as custom or personalized products, hazardous materials, and sale items, are not eligible for return.</p>

                <h4>Exchanges</h4>
                <p>To exchange an item, first request a return for the original product. Once accepted, place a new order for the desired item.</p>

                <h4>Refunds</h4>
                <p>After receiving and inspecting your return, we will notify you of the refund approval status. Approved refunds will be processed back to your original payment method within 10 business days. If more than 15 business days have passed and you have not received your refund, please contact us.</p>
            </div>
        </>
    )
}

export default RefundPolicy;
