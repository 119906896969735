import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async';

function Terms() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <title>FlickNLinK Terms of Service</title>
                <meta name="description" content="FlickNLinK Terms of Service - Review the legal terms and conditions for using our NFC business cards and digital card services." />
                <meta name="keywords" content="FlickNLinK terms of service, terms and conditions, legal terms, NFC business card terms" />
                <meta property="og:title" content="FlickNLinK Terms of Service" />
                <meta property="og:description" content="Understand the terms and conditions for using FlickNLinK NFC business cards and services." />
                <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
                <meta property="og:url" content="https://flicknlinK.com/terms" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className='container policy'>
                <h1>Terms of Service</h1>
                <p>Welcome to FlickNLinK. The terms “we,” “us,” and “our” refer to FlickNLinK, and by accessing our site and services, you agree to these terms. The website and its offerings are subject to your compliance with the conditions outlined here.</p>

                <h4>General Use</h4>
                <p>By using our website, you acknowledge that you are of legal age in your region, and you agree not to use our services for illegal activities. Any unauthorized actions, including misuse of the site, breaches, or violations of these terms, will result in immediate termination of your access.</p>

                <h4>Modifications to Service</h4>
                <p>We reserve the right to modify, suspend, or discontinue our services or prices without notice. FlickNLinK will not be liable for changes that may affect your usage.</p>

                <h4>User Accounts and Information</h4>
                <p>You are responsible for ensuring your account information is accurate and updated. FlickNLinK may limit or cancel orders that appear suspicious, and you agree to provide complete and accurate purchase details. Inaccurate information may result in cancelled orders or delays in service.</p>

                <h4>Third-Party Services</h4>
                <p>FlickNLinK may provide access to third-party tools or services but holds no responsibility for these external platforms. Your use of such services is entirely at your discretion, and we recommend reviewing the terms of those providers.</p>

                <h4>Content Ownership</h4>
                <p>All content on the FlickNLinK platform is owned or licensed by us, including logos, text, and design elements. You may not reproduce, distribute, or modify any of our content without prior written permission.</p>

                <h4>Termination</h4>
                <p>FlickNLinK reserves the right to terminate your account or access to services at any time, especially in cases of violation of these terms.</p>

                <h4>Limitation of Liability</h4>
                <p>We do not guarantee uninterrupted or error-free service. FlickNLinK will not be responsible for any indirect or direct damages arising from your use of our website or services. Use of the platform is at your own risk.</p>

                <h4>Governing Law</h4>
                <p>These terms are governed by the laws of India, and any disputes arising will be subject to the jurisdiction of Indian courts.</p>

                <h4>Changes to Terms</h4>
                <p>We may update these Terms of Service periodically, and your continued use of the site constitutes acceptance of those changes. Please review these terms regularly.</p>

                <h4>Contact Us</h4>
                <p>For any questions about these terms, contact us at flicknlink@gmail.com</p>
            </div>
        </>
    )
}

export default Terms
