import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async';

function ShippingPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <title>FlickNLinK Shipping Policy</title>
                <meta name="description" content="FlickNLinK Shipping Policy - Learn about our shipping procedures and delivery times for NFC business cards." />
                <meta name="keywords" content="FlickNLinK shipping policy, shipping procedures, NFC card delivery, digital card shipping" />
                <meta property="og:title" content="FlickNLinK Shipping Policy" />
                <meta property="og:description" content="Find out how FlickNLinK handles shipping and delivery for our NFC business card products." />
                <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
                <meta property="og:url" content="https://flicknlinK.com/shipping-policy" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className='container policy'>
                <h1>Shipping Policy</h1>

                <p>At FlickNLinK, we aim to deliver your customizable digital products promptly. The production process typically takes two business days, and shipping times range from 4 to 7 business days, depending on your location.</p>

                <h4>Delivery times are as follows:</h4>
                <p>Gujarat: 3-4 business days</p>
                <p>Once your order has shipped, you can track it via the link provided in your confirmation email for real-time updates on its status.</p>
            </div>
        </>
    )
}

export default ShippingPolicy
